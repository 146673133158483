import config from '../../config';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  createImageVariantConfig,
  types as sdkTypes,
} from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { integrationListings } from '../../util/api.stripe';
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST =
  'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS =
  'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR =
  'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST =
  'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS =
  'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST =
  'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS =
  'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLONE_LISTING_REQUEST =
  'app/ManageListingsPage/CLONE_LISTING_REQUEST';
export const CLONE_LISTING_SUCCESS =
  'app/ManageListingsPage/CLONE_LISTING_SUCCESS';
export const CLONE_LISTING_FINISH =
  'app/ManageListingsPage/CLONE_LISTING_FINISH';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';
export const CLONE_LISTING_ERROR = 'app/ManageListingsPage/CLONE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //
const { UUID } = sdkTypes;

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  currentUser: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.listing[listingEntity.id.uuid];
  const updatedListing = {
    ...oldListing,
    attributes: listingEntity.attributes,
  };
  const ownListingEntities = {
    ...state.ownEntities.listing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, listing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        user: payload.user,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }
    case CLONE_LISTING_FINISH:
      return {
        ...state,
        cloneListing: null,
        cloned: null,
      };
    case CLONE_LISTING_REQUEST:
      return {
        ...state,
        cloneListing: payload.listingId,
        cloningListingError: null,
      };
    case CLONE_LISTING_SUCCESS:
      return {
        ...state,
        cloningListing: null,
        cloned: { id: payload.id, slug: payload.slug },
      };
    case CLONE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        cloningListing: null,
        cloningListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;

  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const cloneListingRequest = listingId => ({
  type: CLONE_LISTING_REQUEST,
  payload: { listingId },
});

export const cloneListingSuccess = (id, slug) => ({
  type: CLONE_LISTING_SUCCESS,
  payload: { id, slug },
});

export const cloneListingFinishRequest = () => ({
  type: CLONE_LISTING_FINISH,
});

export const cloneListingError = e => ({
  type: CLONE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = (response, user) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data, user: user },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.currentUser.show().then(res => {
    const paramsWithUser = { ...params, authorId: res.data.data.id.uuid };
    integrationListings(paramsWithUser)
      .then(response => {
        dispatch(addOwnEntities(response));
        dispatch(queryListingsSuccess(response, res.data.data));
        return response;
      })
      .catch(e => {
        dispatch(queryListingsError(storableError(e)));
        throw e;
      });
  });
};

export const closeListing = (listingId, variants) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(closeListingRequest(listingId));

  const variantsClosePromises = [];

  variants.forEach(element => {
    variantsClosePromises.push(
      dispatch(hiddenCloseListing(new UUID(element.id)))
    );
  });

  return Promise.all(variantsClosePromises)
    .then(() => {
      return sdk.ownListings.close({ id: listingId }, { expand: true });
    })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

let requestNewListingVariantsData = listingId => (dispatch, getState, sdk) => {
  return sdk.currentUser.show().then(res => {
    const listingsQueryParams = {
      include: ['publicData'],
      pub_variantOf: listingId,
      page: 1,
      per_page: 100,
    };
    const paramsWithUser = {
      ...listingsQueryParams,
      authorId: res.data.data.id.uuid,
    };

    return integrationListings(paramsWithUser).then(res => {
      let newVariantsTable = res.data.data.map(listing => {
        listing.attributes.publicData.variantType.id = listing.id.uuid;
        return listing.attributes.publicData.variantType;
      });

      return Promise.resolve(newVariantsTable);
    });
  });
};

let requestOldListingVariants = listingId => (dispatch, getState, sdk) => {
  return sdk.currentUser.show().then(res => {
    const listingsQueryParams = {
      include: ['publicData', 'currentStock', 'images'],
      pub_variantOf: listingId,
      page: 1,
      per_page: 100,
    };
    const paramsWithUser = {
      ...listingsQueryParams,
      authorId: res.data.data.id.uuid,
    };

    return integrationListings(paramsWithUser).then(res => {
      return Promise.resolve(res.data.data);
    });
  });
};

// Helper function to make compareAndSetStock call if stock update is needed.
const updateStockOfListingMaybe = (listingId, stockTotals, dispatch) => {
  const { oldTotal, newTotal } = stockTotals || {};
  // Note: newTotal and oldTotal must be given, but oldTotal can be null
  const hasStockTotals = newTotal >= 0 && typeof oldTotal !== 'undefined';

  if (listingId && hasStockTotals) {
    return dispatch(compareAndSetStock(listingId, oldTotal, newTotal));
  }
  return Promise.resolve();
};

// Set stock if requested among listing update info
export function compareAndSetStock(listingId, oldTotal, newTotal) {
  return (dispatch, getState, sdk) => {
    return sdk.stock.compareAndSet(
      { listingId, oldTotal, newTotal },
      { expand: true }
    );
  };
}

export function requestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    const { stockUpdate, images, ...rest } = data;

    // If images should be saved, create array out of the image UUIDs for the API call
    // Note: in FTW, image upload is not happening at the same time as listing creation.
    const imageProperty =
      typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingValues = { ...imageProperty, ...rest };

    const imageVariantInfo = getImageVariantInfo();
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    let createDraftResponse = null;
    return sdk.ownListings
      .createDraft(ownListingValues, queryParams)
      .then(response => {
        createDraftResponse = response;
        const listingId = response.data.data.id;

        // If stockUpdate info is passed through, update stock
        return updateStockOfListingMaybe(listingId, stockUpdate, dispatch);
      })
      .then(() => {
        // Modify store to understand that we have created listing and can redirect away
        return createDraftResponse;
      });
  };
}

export const cloneListing = (listing, variants, slug) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(cloneListingRequest(listing.id));
  try {
    const { variants, ...rest } = listing.attributes.publicData;

    //upload images from parent again, get their id's

    dispatch(requestOldListingVariants(listing.id.uuid)).then(oldVariants => {
      ///
      let newParentData = {
        price: listing.attributes.price,
        //images: listing.images,
        title: listing.attributes.title,
        description: listing.attributes.description,
        publicData: rest,

        stockUpdate: {
          oldTotal: null,
          newTotal: listing.currentStock.attributes.quantity,
        },
      };

      return dispatch(requestCreateListingDraft(newParentData))
        .then(newParentResponse => {
          dispatch(
            cloneListingSuccess(newParentResponse.data.data.id.uuid, slug)
          );

          //TODO: copy variants as well
          //  console.log(newParentResponse);
          // const newParentId = newParentResponse.data.data.id.uuid;

          //  const createVariantClonesPromises = [];
          // oldVariants.forEach(oldVariant => {
          //   console.log('old variant to make intodata', oldVariant);

          //   let newVariantData = {
          //     title: oldVariant.attributes.title,
          //     description: oldVariant.attributes.description,
          //     price: oldVariant.attributes.price,
          //     publicData: {
          //       ...oldVariant.attributes.publicData,
          //       variantOf: newParentId,
          //     },
          //     stockUpdate: {
          //       oldTotal: null,
          //       newTotal: oldVariant.currentStock?.attributes?.quantity ?? 10,
          //     },
          //   };

          //   console.log('variantData to clone', newVariantData);

          //   createVariantClonesPromises.push(
          //     dispatch(requestCreateListingDraft(newVariantData))
          //   );
          // });

          // return Promise.all(createVariantClonesPromises).then(() => {
          //   dispatch(requestNewListingVariantsData(newParentId)).then(
          //     newVariantsTable => {
          //       console.log('final update here');
          //       sdk.ownListings
          //         .update({
          //           id: newParentId,
          //           publicData: { variants: newVariantsTable },
          //         })
          //         .then(finalDraftResult => {
          //           console.log('finalDraftResult', finalDraftResult);
          //           dispatch(cloneListingSuccess(finalDraftResult));
          //         });
          //     }
          //   );
          // });
        })
        .catch(error => {
          dispatch(cloneListingError(error));
        });
      ////
    });
  } catch (error) {
    dispatch(cloneListingError(error));
  }
};

const getImageVariantInfo = () => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

export const cloneListingFinish = () => (dispatch, getState, sdk) => {
  dispatch(cloneListingFinishRequest());
};

export const hiddenCloseListing = (listingId, variants) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(closeListingRequest(listingId));
  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      return response;
    });
};

export const openListing = (listingId, variants) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(openListingRequest(listingId));

  const variantsOpenPromises = [];

  variants.forEach(element => {
    variantsOpenPromises.push(
      dispatch(hiddenOpenListing(new UUID(element.id)))
    );
  });

  return Promise.all(variantsOpenPromises)
    .then(() => {
      return sdk.ownListings.open({ id: listingId }, { expand: true });
    })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      console.log('error');
      dispatch(openListingError(storableError(e)));
    });
};

export const hiddenOpenListing = listingId => (dispatch, getState, sdk) => {
  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      return response;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock', 'publicData'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
    pub_isVariant: false,
  });
};
